import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDZ68MoikFHJJbTduer3CSGk7p82O01kLs",
  authDomain: "pastechart-7f46d.firebaseapp.com", //We should change this, when we have a domain
  projectId: "pastechart-7f46d",
  storageBucket: "pastechart-7f46d.appspot.com",
  messagingSenderId: "963994891927",
  appId: "1:963994891927:web:f9b0d9a6be64daa9fd1bd2",
  measurementId: "G-5SL03MRLTW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Function to handle user sign-up with email and password
export const signupWithEmail = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

// Function to handle user sign-in with email and password
export const loginWithEmail = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

// Function to handle Google sign-in with popup
export const loginWithGoogle = () => {
  return signInWithPopup(auth, googleProvider);
};


