import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCharts } from "../api/api";

const ChartSelector = ({ userUid }) => {
  const [charts, setCharts] = useState([]);
  const { chartId } = useParams();

  useEffect(() => {
    let isMounted = true; // flag to track if the component is mounted

    const fetchCharts = async () => {
      if (!userUid) return;

      try {
        const chartsFromApi = await getCharts(userUid);
        if (isMounted) {
          // Check if still mounted before setting state
          console.log("Fetched charts from API:", chartsFromApi);
          if (Array.isArray(chartsFromApi)) {
            setCharts(chartsFromApi);
          } else {
            console.error("Unexpected response format:", chartsFromApi);
          }
        }
      } catch (error) {
        console.error("Error fetching charts:", error);
      }
    };

    fetchCharts();

    return () => {
      isMounted = false; // Cleanup function to set flag to false
    };
  }, [userUid]);

  const handleChange = (e) => {
    const newChartId = e.target.value;
    window.location.href = `/${newChartId}`;
  };

  return (
    <select value={chartId} onChange={handleChange}>
      <option value="">Select chart</option>
      {charts.length > 0 ? (
        charts.map((chart) => (
          <option key={chart.chartId} value={chart.chartId}>
            {chart.userGivenName || chart.chartId}
          </option>
        ))
      ) : (
        <option disabled>No charts available</option>
      )}
    </select>
  );
};

export default ChartSelector;
