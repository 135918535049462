// src/components/EmailLoginForm.js

import React, { useState } from 'react';
import { loginWithEmail, signupWithEmail } from '../Auth/auth'; // Adjust the path if necessary

const EmailLoginForm = ({ setErrorMessage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailLogin = async () => {
    try {
      await loginWithEmail(email, password);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleEmailSignup = async () => {
    try {
      await signupWithEmail(email, password);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button onClick={handleEmailLogin}>Login with Email</button>
      <button onClick={handleEmailSignup}>Sign Up with Email</button>
    </div>
  );
};

export default EmailLoginForm;
