import React, { useEffect, useState } from "react";
import mermaid from "mermaid";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { saveDiagram, fetchDiagram } from "./api/api";
import { loginWithGoogle } from "./Auth/auth"; // Import auth functions
import { onAuthStateChanged, getAuth } from "firebase/auth";
import EmailLoginForm from "./components/EmailLoginForm"; // Import the new EmailLoginForm component
import "./App.css";
import ChartSelector from "./components/ChartSelector";

function App() {
  const [mermaidCode, setMermaidCode] = useState("");
  const [currentChartUserGivenName, setCurrentChartUserGivenName] =
    useState("");
  const [currentChartOwner, setCurrentChartOwner] = useState(null);
  const [rendered, setRendered] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isPresenterView, setPresenterView] = useState(false);
  const [user, setUser] = useState(null);
  const [newUserGivenName, setChartName] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // New loading state

  const defaultMermaidCode = `graph TD\n    A-->B\n    A-->C\n    B-->D\n    C-->D`;

  useEffect(() => {
    const path = window.location.pathname;
    const currentSessionId = path.split("/")[1];

    if (!currentSessionId || currentSessionId === "") {
      setMermaidCode(defaultMermaidCode);
    } else {
      setSessionId(currentSessionId);
    }
  }, [navigate, defaultMermaidCode]);

  useEffect(() => {
    const loadDiagram = async () => {
      if (!sessionId || isSaving) return;

      try {
        const response = await fetchDiagram(sessionId);
        setErrorMessage(null);
        setMermaidCode(response.mermaidCode || defaultMermaidCode);
        setCurrentChartUserGivenName(response.userGivenName || "");
        setCurrentChartOwner(response.userUid || null);
      } catch (error) {
        setErrorMessage(error.message);
        setMermaidCode(defaultMermaidCode);
      }
    };

    loadDiagram();
  }, [sessionId, defaultMermaidCode, isSaving]);

  const handleChange = (e) => {
    setMermaidCode(e.target.value);
  };

  const handleSave = async () => {
    let idToSave = sessionId;

    if (!sessionId) {
      const generatedId = uuidv4();
      setSessionId(generatedId);
      idToSave = generatedId;
      navigate(`/${generatedId}`);
    }

    setIsSaving(true);
    try {
      await saveDiagram(idToSave, mermaidCode, user.uid, newUserGivenName);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(error.message);
      navigate(`/`);
    } finally {
      setIsSaving(false);
    }
  };

  const handleNewDiagram = async () => {
    navigate(`/`);
    setSessionId(null);
    setCurrentChartUserGivenName("");
    setCurrentChartOwner(user.uid);
    setMermaidCode(defaultMermaidCode);
  };

  const handlePresenterView = async () => {
    setPresenterView(!isPresenterView);
  };

  const handleLoginWithGoogle = async () => {
    try {
      await loginWithGoogle();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleLogout = async () => {
    try {
      await getAuth().signOut();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (currentUser) => {
      setUser(currentUser ? currentUser : null);
      setLoading(false); // Set loading to false once user state is determined
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    mermaid.initialize({ startOnLoad: false });

    const renderMermaid = async () => {
      if (mermaidCode.trim() === "") {
        return;
      }
      try {
        const { svg } = await mermaid.render("mermaidDiagram", mermaidCode);
        setRendered(svg);
      } catch (error) {
        console.error("Error rendering Mermaid diagram:", error);
      }
    };

    renderMermaid();
  }, [mermaidCode]);

  return (
    <div className="App">
      {loading ? (
        <p>Loading...</p>
      ) : user ? (
        <div hidden={isPresenterView}>
          <p>Logged in as: {user.displayName || user.email}!</p>
          <button onClick={handleLogout}>Logout</button>
          {user.uid && <ChartSelector userUid={user.uid} />}
        </div>
      ) : (
        <>
          <button hidden={isPresenterView} onClick={handleLoginWithGoogle}>
            Login with Google
          </button>
          <EmailLoginForm
            hidden={isPresenterView}
            setErrorMessage={setErrorMessage}
          />
        </>
      )}
      <img
        src="logo.png"
        alt="logo"
        class="logo-image"
        hidden={isPresenterView}
      />

      <h1 hidden={isPresenterView}>No time to waste, just copy & paste</h1>
      <h2>{currentChartUserGivenName || "Give me a fancy name"}</h2>

      <textarea
        rows="10"
        cols="30"
        value={mermaidCode}
        onChange={handleChange}
        hidden={isPresenterView}
      />
      <input
        type="text"
        placeholder="My fancy chart"
        value={newUserGivenName}
        hidden={isPresenterView}
        onChange={(e) => setChartName(e.target.value)}
      />
      <button
        hidden={isPresenterView}
        onClick={handleSave}
        disabled={!user || user.uid !== currentChartOwner}
      >
        Save Diagram
      </button>
      <button hidden={isPresenterView} onClick={handleNewDiagram}>
        New Diagram
      </button>
      <button onClick={handlePresenterView}>
        {isPresenterView ? "Editing View" : "Presenter View"}
      </button>
      <div className="mermaid" dangerouslySetInnerHTML={{ __html: rendered }} />
      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage(null)}>Close</button>
        </div>
      )}
    </div>
  );
}

export default App;
