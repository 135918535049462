const API_BASE_URL = process.env.REACT_APP_API_URL;

// Function to save the diagram
export const saveDiagram = async (sessionId, mermaidCode, uid, userGivenName) => {
    const url = `${API_BASE_URL}/saveChart`;
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId, mermaidCode, uid, userGivenName }),
    };

    const response = await fetch(url, options);

    if (!response.ok) {
        const error = new Error(`Failed to save the diagram: ${response.statusText}`);
        const errorData = await response.json();
        error.message = errorData.message || error.message;
        throw error;
    }

    return await response.json();
};

export const fetchDiagram = async sessionId => {
    const url = `${API_BASE_URL}/getChart?sessionId=${encodeURIComponent(sessionId)}`;
    const response = await fetch(url);
    
    if (!response.ok) {
        const error = new Error(`Failed to load diagram: ${response.status} ${response.statusText}`);
        const errorData = await response.json();
        error.message = errorData?.message || error.message;
        throw error;
    }

    const { mermaidCode, userGivenName } = await response.json();
    return { mermaidCode, userGivenName };
};

export const getCharts = async (userUid) => {
    const url = `${API_BASE_URL}/getUserCharts?userUid=${encodeURIComponent(userUid)}`;
    const response = await fetch(url);
    
    if (!response.ok) {
        const error = new Error(`Failed to load charts: ${response.status} ${response.statusText}`);
        const errorData = await response.json();
        error.message = errorData?.message || error.message;
        throw error;
    }

    const charts = await response.json(); // Directly parse as array
    console.log("Fetched charts:", charts); // Log the fetched charts
    return charts; // Return the array directly
};


